import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { LocationProvider } from '@reach/router';
import styled from 'styled-components';
import devices from '../../../../../js/styles/Devices';
import Disclaimer from '../../../../utils/Disclaimer/Disclaimer';

import ExtraFormFunctionalityWrapper from '../../ExtraFormFunctionalityWrapper';
import Form from '../../../../common/Form';
import WarningDisclaimerWithIntl from '../../../../utils/Disclaimer/WarningDisclaimer';

const Wrapper = styled.div`
  && {
    position: relative;
    text-align: left;
    max-width: 36rem;
    margin: auto;
  }
`;

const FormBlockWrap = styled.div`
  && {
    margin: auto;
    position: relative;
    overflow-x: hidden;
    background: var(--white);
    text-align: left;
    border-radius: 4px;
    box-shadow: var(--form-shadow);
    padding-top: 0;
    max-width: 36rem;
  }
`;

const FormFrame = styled.div`
  && {
    margin: auto;
    margin-top: auto;

    @media ${devices.downFromTablet} {
      margin-top: auto;
    }

    @media ${devices.downFromMobile} {
      width: 100%;
    }

    .fullview-backdrop:after{
      background: url("data:image/svg+xml,%3Csvg width='44' height='23' viewBox='0 0 44 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3283 23.0002C22.2273 23.0002 22.127 23.0002 22.026 22.9933C17.8657 22.8157 15.6896 19.2905 15.6896 15.8997C15.6896 12.4612 17.4445 9.58229 20.0263 7.55488C18.5372 6.38405 16.6874 5.75314 14.785 5.76727C6.54694 5.76727 5.43558 13.7055 5.43558 21.4708H0.597656C0.597656 14.7077 1.6857 9.77826 3.93019 6.399C6.31379 2.80532 9.96652 0.983887 14.785 0.983887C18.2124 0.983887 21.7019 2.45103 24.3668 5.00949C24.416 5.0556 24.4642 5.10248 24.5114 5.15013C26.1083 4.60363 27.7854 4.32112 29.4752 4.31396C33.4924 4.31396 37.1009 5.93634 39.6383 8.88138C42.2706 11.9386 43.6626 16.2916 43.6626 21.4708H38.8254C38.8254 13.8384 35.2426 9.09734 29.4752 9.09734C28.7955 9.09655 28.1173 9.15804 27.4491 9.28102C28.4254 11.3547 28.926 13.6162 28.9149 15.9035C28.9149 18.06 28.1594 19.9629 26.7869 21.2618C25.5857 22.3922 23.9865 23.0158 22.3283 23.0002ZM22.9866 11.323C21.4322 12.6172 20.5268 14.3042 20.5268 15.8997C20.5268 17.303 21.1656 18.1684 22.2366 18.2145C22.5723 18.2276 23.0565 18.1692 23.4428 17.8033C23.8531 17.4152 24.0785 16.7389 24.0785 15.8997C24.0769 14.4648 23.7086 12.8424 22.9866 11.323Z' fill='%23DF202D'/%3E%3C/svg%3E") no-repeat left bottom -2.2rem !important;
      background-size: 56.25rem !important;
      background-color: var(--sambla-red)!important ;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: " ";
      z-index: -1;
    }
  }
`;

const FormBlock = ({
  show,
  disclaimer,
  forwardRef,
  exampleLoan,
  title,
  partnerForm,
  prefilledApplicationForm,
  prefilledValues,
  subtitle,
  type,
  aboveFold,
  ...rest
}) => {
  if (!show) return null;

  const isMortgage = type[0] === 'mortgageLoan';

  return (
    <Wrapper>
      <FormBlockWrap
        ref={forwardRef}
        id="form"
      >
        <FormFrame>
          <ExtraFormFunctionalityWrapper>
            <LocationProvider>
              {(context) => (
                <>
                  <Form
                    key="form-block"
                    exampleLoan={exampleLoan}
                    title={title}
                    subtitle={subtitle}
                    location={context.location}
                    partnerForm={partnerForm}
                    prefilledApplicationForm={prefilledApplicationForm}
                    prefilledValues={prefilledValues}
                    type={type[0]}
                    {...rest}
                  />
                </>
              )}
            </LocationProvider>
          </ExtraFormFunctionalityWrapper>
        </FormFrame>
      </FormBlockWrap>
      {!isMortgage && (
        <div>
          <Disclaimer
            isAboveFold={aboveFold}
          />
          <WarningDisclaimerWithIntl />
        </div>
      )}
    </Wrapper>
  );
};

FormBlock.propTypes = {
  show: PropTypes.bool,
  disclaimer: PropTypes.string,
  inputSource: PropTypes.string,
  advisaSource: PropTypes.string,
  forwardRef: PropTypes.shape(),
  exampleLoan: PropTypes.shape(),
  title: PropTypes.string,
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  subtitle: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  aboveFold: PropTypes.bool,
};

FormBlock.defaultProps = {
  show: true,
  type: [''],
  disclaimer: null,
  inputSource: '',
  advisaSource: '',
  aboveFold: false,
  forwardRef: null,
  exampleLoan: null,
  title: null,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  subtitle: null,
};

export default FormBlock;

export const formBlockQuery = graphql`
  fragment formBlockFields on Form {
    show
    type
    title
    subtitle
    type
    inputSource
    advisaSource
  }
`;
